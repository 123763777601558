import React from "react";
import "./produkbsd.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Hieraimages from "./hiera.js";

import Adoraimages from "./Adora.js";
import Armontimages from "./Armont.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsUpDownLeftRight} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Adora = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281770998006&text=Halo%20Andi,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20Adora%20Primes%20https://eonna-bsdcity.com/%20&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Armont = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281770998006&text=Halo%20Andi,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20Armont%20https://eonna-bsdcity.com/%20&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Hiera = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281770998006&text=Halo%20Andi,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20Hiera%20https://eonna-bsdcity.com/%20&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="container">
      <div className="containercard3">
        <div className="card">
          <Slider {...settings}>
            {Armontimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Armont</div>
            </div>
            <div className="cicilan">
              <div className="angka">Subsidi DP, </div>
              <div className="ket">Start From 24 Juta/Bulan</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#0f1c3f" icon={faArrowsUpDownLeftRight} />
            <span> 200m²</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faHouse} />
            <span> 380m²</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faBed} />
            <span> 5+1</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faShower} />
            <span> 6+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Armont} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Hieraimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Wynyard</div>
            </div>
            <div className="cicilan">
              <div className="angka">Subsidi DP 10%,</div>
              <div className="ket"> Start From 18 Juta/Bulan</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#0f1c3f" icon={faArrowsUpDownLeftRight} />
            <span> 98m²</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faHouse} />
            <span> 160m²</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faBed} />
            <span> 4+1+1</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faShower} />
            <span> 3+1+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Hiera} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Adoraimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Adora Primes</div>
            </div>
            <div className="cicilan">
              <div className="angka">Tanpa DP,</div>
              <div className="ket">Start From 13 Juta/Bulan</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#0f1c3f" icon={faArrowsUpDownLeftRight} />
            <span> 84m²</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faHouse} />
            <span> 144m²</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faBed} />
            <span> 5+1</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faShower} />
            <span> 3+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Adora} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
