import React from "react";
import "./home.scss";
import Header from "../section/Header/header.jsx";
import Banner from "../section/Banner/banner.jsx";
import About from "../section/About/about.jsx";
import Promo from "../section/Promo/promo.jsx";
import Rumah from "../section/Rumah/rumah.jsx";
import Fasilitas from "../section/Fasilitas/fasilitas.jsx";
import Lokasi from "../section/Lokasi/lokasi.jsx";
import Footer from "../section/Footer/footer.jsx";
import Cluster from "../section/Cluster/cluster.jsx";
import Othercluster from "../section/Othercluster/othercluster.jsx";
import Otherbsd from "../section/Otherbsd/otherbsd.jsx";
import tombol from "../media/tombol.webp";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ScrollToHashElement from "./ScrollToHashElement.js";

const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281770998006&text=Halo%20Andi,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20https://eonna-bsdcity.com/%20&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  return (
    <div className="home">
      <ScrollToHashElement />
      <Header />
      <Banner />
      <About />
      <Promo />
      <Fasilitas />
      <Cluster />
      <Rumah />
      <Othercluster />
      <Otherbsd />
      <Lokasi />
      <Footer />
      <img
        onClick={fungsiganteng}
        src={tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
      <div className="wamobile">
        <button onClick={fungsiganteng} className="mobile">
          <div>
            <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp Sekarang
          </div>
        </button>
      </div>
    </div>
  );
};

export default home;
