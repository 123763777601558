import React from "react";
import "./header.scss";
import Logo from "../../media/EONNA.webp";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const headereonna = () => {
  const waheader = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281770998006&text=Halo%20Andi,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20https://eonna-bsdcity.com/%20()&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  return (
    <div className="container-headereonna">
      <div className="logo">
        <img className="gambareonna" src={Logo} alt="logo eonna" />
      </div>
      <div className="container-navbar">
        <div className="navbar">
          <div className="menu-navbar">
            <ul>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#promo">Promo</a>
              </li>
              <li>
                <a href="#rumah">Residentials</a>
              </li>
              <li>
                <a href="#location">Location</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-buttoneonna">
        <button className="whatsapp" onClick={waheader}>
          <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
        </button>
      </div>
    </div>
  );
};

export default headereonna;
