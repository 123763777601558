import React from "react";
import "./about.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import logoeonna from "../../media/EONNA.webp";

const abouteonna = () => {
  const aboutwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281770998006&text=Halo%20Andi,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20https://eonna-bsdcity.com/%20()&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="about">
      <div id="about" className="container-about">
        <div className="heading-about">
          <div className="logo">
            <img className="gambareonna" src={logoeonna} alt="logo eonna" />
          </div>
          <div className="judul2">Tribute to Perfection</div>
          <div className="desk">
            Eonna BSD adalah perumahan mewah di Kota BSD, Tangerang Selatan,
            Banten. Terletak di kawasan premium Asian Inspired Lifestyle Centre,
            perumahan ini menawarkan hunian mewah dan eksklusif dengan desain
            Korean Inspired Newtro Design. Cluster Aerra memiliki konsep rumah
            kontemporer tropika modern kontemporer. Fitur Breathing Living Space
            dan Inner Courtyard juga tersedia dalam Tipe Lebar 8. Hunian
            eksklusif dan mewah dari Eonna BSD memiliki banyak fasilitas dan
            lokasi yang dirancang dengan baik.
          </div>
          <div className="button">
            <button className="wa" onClick={aboutwa}>
              <FontAwesomeIcon icon={faWhatsapp} />
              &nbsp; More Info
            </button>
          </div>
        </div>
        <div className="gambarabout"></div>
      </div>
    </div>
  );
};

export default abouteonna;
