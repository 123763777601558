import React from "react";
import "./Produk1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import L8images from "./eonnal8.js";
import L9images from "./eonnal9.js";
import L10images from "./eonna10.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsUpDownLeftRight} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Aerra8 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281770998006&text=Halo%20Andi,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20Aerra8%20https://eonna-bsdcity.com/%20&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Aerra9 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281770998006&text=Halo%20Andi,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20Aerra9%20https://eonna-bsdcity.com/%20&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Aerra10 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281770998006&text=Halo%20Andi,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20Aerra10%20https://eonna-bsdcity.com/%20&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="container">
      <div className="containercard1">
        <div className="card">
          <Slider {...settings}>
            {L8images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Aerra 8</div>
            </div>
            <div className="cicilan">
              <div className="angka">Tanpa DP,</div>
              <div className="ket"> 22 Juta/Bulan</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#0f1c3f" icon={faArrowsUpDownLeftRight} />
            <span> 128m²</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faHouse} />
            <span> 192m²</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faBed} />
            <span> 4+1+1</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faShower} />
            <span> 4+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Aerra8} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {L9images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Aerra 9</div>
            </div>
            <div className="cicilan">
              <div className="angka">Tanpa DP,</div>
              <div className="ket">27 Juta/Bulan</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#0f1c3f" icon={faArrowsUpDownLeftRight} />
            <span> 144m²</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faHouse} />
            <span> 255m²</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faBed} />
            <span> 4+1+1</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faShower} />
            <span> 4+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Aerra9} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {L10images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Aerra 10</div>
            </div>
            <div className="cicilan">
              <div className="angka">Tanpa DP,</div>
              <div className="ket"> 36 Juta/Bulan</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#0f1c3f" icon={faArrowsUpDownLeftRight} />
            <span> 180m²</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faHouse} />
            <span> 325m²</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faBed} />
            <span> 4+1+1</span>
            <FontAwesomeIcon color="#0f1c3f" icon={faShower} />
            <span> 5+1+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Aerra10} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
