import Armont1 from '../../../media/Eonna/Others/Armont/Armont  (1).webp'
import Armont2 from '../../../media/Eonna/Others/Armont/Armont  (1).webp'
import Armont3 from '../../../media/Eonna/Others/Armont/Armont  (1).webp'
import Armont4 from '../../../media/Eonna/Others/Armont/Armont  (1).webp'
import Armont5 from '../../../media/Eonna/Others/Armont/Armont  (1).webp'
import Armont6 from '../../../media/Eonna/Others/Armont/Armont  (1).webp'
import Armont7 from '../../../media/Eonna/Others/Armont/Armont  (1).webp'
import Armont8 from '../../../media/Eonna/Others/Armont/Armont  (1).webp'
import Armont9 from '../../../media/Eonna/Others/Armont/Armont  (1).webp'
import Armont10 from '../../../media/Eonna/Others/Armont/Armont  (10).webp'




const Armontimages =[
Armont1,Armont2,Armont3,Armont4,Armont5,Armont6,Armont7,Armont8,Armont9,Armont10
]

export default Armontimages;