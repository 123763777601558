import React from "react";
import "./Produk2.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Namee8images from "./namee8.js";
import Namee9images from "./namee9.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsUpDownLeftRight} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Produk2 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Namee8 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281770998006&text=Halo%20Andi,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20Namee8%20https://eonna-bsdcity.com/%20&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Namee9 = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281770998006&text=Halo%20Andi,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20Namee9%20https://eonna-bsdcity.com/%20&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="rumah">
      <div id="rumah1" className="container-header1">
        {" "}
        Namee
      </div>
      <div>
        <div className="Judul"></div>
        <div className="rumah-card"></div>
      </div>
      <div className="container">
        <div className="containercard2">
          <div className="card">
            <Slider {...settings}>
              {Namee8images.map((image, index) => (
                <img className="imagecard" key={index} src={image} />
              ))}
            </Slider>
            <div className="container-judul-kartu">
              <div className="judulkartu">
                <div className="nama-rumah">Namee 8</div>
              </div>
              <div className="cicilan">
                <div className="angka">Tanpa DP,</div>
                <div className="ket"> 22 Juta/Bulan</div>
              </div>
            </div>
            <hr />
            <div className="gridspek">
              <FontAwesomeIcon color="#0f1c3f" icon={faArrowsUpDownLeftRight} />
              <span> 128m²</span>
              <FontAwesomeIcon color="#0f1c3f" icon={faHouse} />
              <span> 192m²</span>
              <FontAwesomeIcon color="#0f1c3f" icon={faBed} />
              <span> 4+1+1</span>
              <FontAwesomeIcon color="#0f1c3f" icon={faShower} />
              <span> 4+1</span>
            </div>
            <div className="containerwhatsapp">
              <button onClick={Namee8} className="whatsapp">
                <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
              </button>
            </div>
          </div>
          <div className="card">
            <Slider {...settings}>
              {Namee9images.map((image, index) => (
                <img className="imagecard" key={index} src={image} />
              ))}
            </Slider>
            <div className="container-judul-kartu">
              <div className="judulkartu">
                <div className="nama-rumah">Namee 9</div>
              </div>
              <div className="cicilan">
                <div className="angka">Tanpa DP,</div>
                <div className="ket">27 Juta/Bulan</div>
              </div>
            </div>
            <hr />
            <div className="gridspek">
              <FontAwesomeIcon color="#0f1c3f" icon={faArrowsUpDownLeftRight} />
              <span> 144m²</span>
              <FontAwesomeIcon color="#0f1c3f" icon={faHouse} />
              <span> 255m²</span>
              <FontAwesomeIcon color="#0f1c3f" icon={faBed} />
              <span> 4+1+1</span>
              <FontAwesomeIcon color="#0f1c3f" icon={faShower} />
              <span> 4+1</span>
            </div>
            <div className="containerwhatsapp">
              <button onClick={Namee9} className="whatsapp">
                <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
              </button>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Produk2;
